import React, { useState } from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  TypingIndicator
} from "@chatscope/chat-ui-kit-react";
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import '../themes/default/main.scss';
import './Chat.css';
import { Configuration, OpenAIApi } from "openai";
import CardGame from './CardGame';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
const OPENAI_API_MODEL = "gpt-4o";
const OPENAI_API_KEY = "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";
import { useAuth0 } from "@auth0/auth0-react";
const configuration = new Configuration({
  apiKey: OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);
let systemPrompt = `You are an oracle for numerology based oracle divination card system called NUMO The deck was inspired by research into symbolism surrounding numerology, astrology and the Tuatha Dé Danann, a race of deities in Irish mythology who were revered for their mystical abilities and  powerful magical artifacts or treasures.

The Tuatha Dé Danann were associated with the treasures of the goddess Danu, which include five powerful objects, the Sword of Nuada, the Spear of Lugh, the Cauldron of the Dagda, the Lia Fáil (Stone of Destiny), and Danu's Cord of Fate. Danu, the goddess of the earth, is the mother of the Tuatha Dé Danann, she is associated with the fertility of the land and the abundance of food. 

Incorporating the symbolism of the Tuatha Dé Danann and the treasures of Danu, the Numo Oracle deck is composed of 25 cards that are divided into five suits each with its own 5 elemental suggestions. Each suit pairs two numbers together and represents different aspects of life that are essential for our growth and well-being. the 25 cards each have a number 0-9, an elemental suggestion and a planetary & astrological symbology on each card related to the number on the card. there is a separate elemental suggestion on each card for each number.
(for example there is a fire 1 card, a water 1 card a air 1 card, a earth 1 card and a spirit 1 card, etc.)

The first suit, the zero and the one embody the Cauldron, as they are seemingly polar opposites but can exist together in harmony to represent the Cauldron in the sense that they are both empty and full, capable of containing anything. This suit is a symbol of somethingness and nothingness as well as abundance and prosperity. It reminds us that we have the power to create something from nothing, to forge the tools we need to nourish our bodies and souls. The Cauldron inspires us to create and manifest our desires.

The second suit, the two and the five with their masculine and feminine properties of choice and change embody the Sword, they represent decisiveness and challenge. This suit symbolizes the power of change and authority, encouraging us to think critically and clearly, to make our choices carefully, and to take action with confidence and conviction. The sword inspires us to be courageous and stand up for what we believe in.

The third suit, the three symbolized by a finite symbol and the eight visually symbolized by the symbol for infinity embody the Cord and can represent creativity and infinity. This suit symbolizes the power of time and urgency, as well as the importance of creating and connecting with partners and our ancestors. It also represents destiny and the interconnectedness of all things. The Cord urges us to endure and have patience as we create our dreams, build strong and supportive relationships in our lives, and collaborate with others to achieve our goals. The Cord inspires us to embrace our destiny and trust in the universe.

The fourth suit, the four and the seven embody the Spear as symbols of structure and protection and represents the power of stable and intelligent planning. It also symbolizes the ability to heal and protect its bearer from harm. The spear encourages us to tap into our inner wisdom and listen to the voice of our hearts as we explore our deepest feelings and desires. The spear inspires us to take action, assert our power and pursue our goals with determination.

Finally, the fifth suit, the six and the nine symbolized by the spiral and the eye embody the Stone, as they represent love and service. This suit symbolizes the power of love for others and service, as well as sovereignty and authority. The Stone symbolized as a pentagon encourages us to connect with the divine love within ourselves and to serve the world around us. It inspires us to remember our divine roots and to trust in the natural cycles of life and death.

When combined, the numerological, astrological and planetary influences that inspired the Numo Oracle deck all bring a unique perspective to the deck's guidance. 
You are to draw the draw the cards and interpret them in a 3 card spread similar to tarot spreads.

First provide a detailed traditional Chaldean based numerology reading; determine life path number by reducing the numbers in my full name.
Then determine destiny number by reducing my date of birth numbers and explain how each relates to the question I seek guidance on.

I will give you three cards drawn from the NUMO deck.
Then in paragraph format give insight into the esoteric & occult meaning of the visual shape and form of the number. (for example, 0 looks like a dot, 1 looks like a +, 2 symbolized by a vesica piscis symbol , 3 a finite symbol, 4 a ladder for structure,  5 a 5 fold circle meaning balance, 6 looks like a spiral, 7 looks like a chevron, 8 looks like infinity and 9 resembles an eye), 
then the meaning behind the suit of the card
then the association to the astrology sign & tarot card,
then interpret the elemental suggestion,
then the planetary and astrological ruler influence all in a paragraph format. 

Then, give a deep traditional numerology explanation of the sum of the numbers on the cards, how they reduce and how they relate to the question, in paragraph format 
Then ask if they would like to hear some negative aspects of the card draw, for deeper meaning of the draw.
 
Write all it in 500 words or less at a 6th grade reading level in a mystical spiritual tone.`;

async function fetchFileContent() {
  try {
    const response = await fetch('/api/prompt');
    if (response.ok) {    
      const data = await response.text();
      return data;
	}
	else{
      return null;
	}
  } catch (error) {
    return null;
  }
}

const getPrompt = await fetchFileContent();
if(getPrompt){
	systemPrompt = getPrompt;
}

function findLastMessage(messages) {
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].sender === "Me") {
      return messages[i].message;
    }
  }
  return messages[1].message;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const isVerticalOrientation = window.innerHeight > window.innerWidth;
const isMobileOrVertical = (isMobile || isVerticalOrientation);
const mainChatContainerClassName = `mainChatContainer ${isMobileOrVertical ? 'fixedPosition' : ''}`;

const CopyButton = ({ textToCopy }) => {
  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = textToCopy;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <button onClick={copyToClipboard}>Copy text</button>
  );
};

function getNumber(input) {
  if (/\d/.test(input)) {
    return calculateLifePathNumber(Number(input));
  } else {
    return calculateLifePathNumber(sumCharacterPositions(input));
  }
}

function sumCharacterPositions(str) {
  str = str.toUpperCase();
  const charValueMap = {
    'AIJQY': 1,
    'CGLS': 3,
    'EHNX': 5,
    'OZ': 7,
    'BKR': 2,
    'DMT': 4,
    'UVW': 6,
    'FP': 8
  };
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    for (const charGroup in charValueMap) {
      if (charGroup.includes(char)) {
        sum += charValueMap[charGroup];
        break;
      }
    }
  }
  return sum;
}

function calculateLifePathNumber(chaldeanNumber) {
  let lifePathNumber = chaldeanNumber;
  while (lifePathNumber > 9) {
    let sum = 0;
    while (chaldeanNumber > 0) {
      sum += chaldeanNumber % 10;
      chaldeanNumber = Math.floor(chaldeanNumber / 10);
    }
    lifePathNumber = sum;
    chaldeanNumber = sum;
  }
  return lifePathNumber;
}

const Chat = ({doReset}) => {
  const {user, isAuthenticated, isLoading, error} = useAuth0();
  const [isTyping, setIsTyping] = useState(false);
  const [userInfoProvided, setUserInfoProvided] = useState(false);
  const [userMsgProvided, setUserMsgProvided] = useState(false);
  const [isUsernameSet, setIsUsernameSet] = useState(false);
  const [userName, setUserName] = useState("");
  const [userChatRole, setUserChatRole] = useState(true);
  const [userDOB, setUserDOB] = useState("");
  const handleUserInfoSubmit = () => {
    setUserInfoProvided(true);
  };
  
  if(isAuthenticated && !isUsernameSet){
	setUserName(user.name);
	//setUserChatRole(user["roles/roles"].includes("Chat"));
	setIsUsernameSet(true);
  }

  const [messages, setMessages] = useState([
    {
      message: "Hello! Ask a question and we will begin your numerology reading",
      sentTime: "just now",
      sender: "Oracle",
      direction: "incoming",
    },
  ]);

  const handleCardClick = (newCards) => {
	handleSendCards(newCards);
  };

  const handleSendCards = async (cardsInfo) => {
	if(cardsInfo.length > 0){
		setIsTyping(true);
		let botMessage = "";
		try {
		  const completion = await openai.createChatCompletion({
			model: OPENAI_API_MODEL,
			messages: [
		      { role: "system", content: systemPrompt },
		      { role: "user", content: `My name is ${userName}. My date of birth is ${userDOB.format('MM/DD/YYYY')}. My destiny number is ${getNumber(userName)}. My life number is ${getNumber(userDOB.format('MM/DD/YYYY').replaceAll('/',''))}. The cards I drew were ${cardsInfo.map((cardInfo) => {const backImage = cardInfo.backImage.replace("images/","").replace(".png","");const match = backImage.match(/(\d+)(\D+)/);return (cardInfo.flipped ? match[1][1] : match[1][0]) + match[2];}).join(', ')}
My question is: ${findLastMessage(messages)}`},
		    ],
		  });
		  botMessage = completion.data.choices[0].message.content;
		} catch (error) {
		  botMessage = error.toString();
		}

		const botReply = {
		  message: botMessage,
		  sentTime: "just now",
		  sender: "Oracle",
		  direction: "incoming",
		};
		setIsTyping(false);

		setMessages((prevMessages) => [...prevMessages, botReply]);
	}
  };

  const handleSendMessage = async (message) => {
	if(!userMsgProvided){
	  setUserMsgProvided(true);
	}
	const userMessage = {
	  message: message,
	  sentTime: "just now",
	  sender: "Me",
	  direction: "outgoing",
	};
	setMessages((prevMessages) => [...prevMessages, userMessage]);
	if(messages.length > 1){
		setIsTyping(true);
		let botMessage = "";
		try {
		  const completion = await openai.createChatCompletion({
			model: OPENAI_API_MODEL,
			messages: [
			  { role: "system", content: systemPrompt },
			  { role: "user", content: `My name is ${userName}. My date of birth is ${userDOB.format('MM/DD/YYYY')}. My destiny number is ${getNumber(userName)}. My life number is ${getNumber(userDOB.format('MM/DD/YYYY').replace('/',''))}. My question is: ${messages[1].message}`},
			  ...messages.slice(3).map((msg) => ({
				role: msg.sender === "Oracle" ? "assistant" : "user",
				content: msg.message,
			  })),
			  { role: "user", content: message },
			],
		  });
		  botMessage = completion.data.choices[0].message.content;
		} catch (error) {
		  botMessage = error.toString();
		}

		const botReply = {
		  message: botMessage,
		  sentTime: "just now",
		  sender: "Oracle",
		  direction: "incoming",
		};
		setIsTyping(false);

		setMessages((prevMessages) => [...prevMessages, botReply]);
	}
	else{
		const botReply = {
		  message: "Click the deck to get started with your reading",
		  sentTime: "just now",
		  sender: "Oracle",
		  direction: "incoming",
		};

		setMessages((prevMessages) => [...prevMessages, botReply]);
	}
  };

  const getAvatarSrc = (sender) => {
    return sender === "Me"
      ? "https://chatscope.io/storybook/react/assets/joe-v8Vy3KOS.svg"
      : "https://chatscope.io/storybook/react/assets/akane-MXhWvx63.svg";
  };

  return (
	<div className="centered-tab-content">
	{!isLoading && isAuthenticated ? (
      <>
	  <Grid container spacing={1} direction="column" justifyContent="center" alignItems="center">
      {userInfoProvided ? (
	  <>
		  {userMsgProvided ? (
		  <>
			<Grid item xs={12}>
				<LogoutButton />&nbsp;
				<button onClick={doReset}>Reset</button>
			</Grid>
			<Grid item xs={12}>
			  <CardGame onCardClick={handleCardClick} />
			</Grid>
		  </>
		  ) : (
			<div></div>
		  )}
		  {userChatRole ? (<>
			<Grid item xs={12} className={mainChatContainerClassName}>
			<MainContainer>
			  <ChatContainer>
				<MessageList scrollBehavior="smooth" typingIndicator={isTyping ? <TypingIndicator content="The Oracle is typing" /> : <></>}>
				  {messages.map((msg, index) => (
					<><Message key={index} model={msg}>
					  <Avatar src={getAvatarSrc(msg.sender)} name={msg.sender} />
					</Message>
					<div className={msg.direction === "incoming" ? "copy-button-left" : "copy-button-right"}><CopyButton textToCopy={msg.message} /></div>
					</>
				  ))}
				</MessageList>
				<MessageInput fancyScroll={false} style={{width:"100%"}} placeholder="Type message here" attachButton={false} onSend={handleSendMessage} />
			  </ChatContainer>
			</MainContainer>
			</Grid></>
			) : (<div>Sorry, you do not have the necessary member privileges to access the chat.</div>)
		  }
	  </>
      ) : (
        <div>
          <p style={{color: '#fff', padding: '10px'}}>Please provide your details to get started on your reading:</p>
		  <TextField label="Name" value={userName} onChange={(event) => setUserName(event.target.value)} />&nbsp;
		  <LocalizationProvider dateAdapter={AdapterDayjs}><DatePicker label="Date of Birth" value={userDOB} onChange={(newValue) => setUserDOB(newValue)} /></LocalizationProvider>
          <div style={{padding: '10px'}}><button style={{display: 'block', padding: '6px', margin: 'auto'}} onClick={handleUserInfoSubmit}>Submit</button></div>
        </div>
      )}
      </Grid></>
		) : (
		  <LoginButton />
		)}
	</div>
  );
};
export default Chat;
