// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainChatContainer {
  bottom: 0;
  height: 35vh;
  overflow-y: auto;
}

.fixedPosition {
	position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/components/Chat.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,YAAY;EACZ,gBAAgB;AAClB;;AAEA;CACC,eAAe;AAChB","sourcesContent":[".mainChatContainer {\r\n  bottom: 0;\r\n  height: 35vh;\r\n  overflow-y: auto;\r\n}\r\n\r\n.fixedPosition {\r\n\tposition: fixed;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
