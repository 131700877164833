import React, { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ViewMode } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const GuideBook = () => {
  const {user, isAuthenticated, isLoading, error} = useAuth0();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [selectedFileUrl, setSelectedFileUrl] = useState('NUMO-GuideBook-v.3.03.pdf');
  const [isUsernameSet, setIsUsernameSet] = useState(false);
  const [userGuidebookRole, setUserGuidebookRole] = useState(false);
  const fileUrlOptions = [
    {label: 'Main Guidebook', value: 'NUMO-GuideBook-v.3.03.pdf'},
    {label: 'Compound Numbers', value: 'NUMO-Compound-Numbers.pdf'},
  ];
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if(isAuthenticated && !isUsernameSet){
	setUserGuidebookRole(user["roles/roles"].includes("Guidebook"));
	setIsUsernameSet(true);
  }
  return (
	<div className="centered-tab-content" style={{display: 'contents'}}>
	{!isLoading && isAuthenticated ? (
	  <>
	  <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
	      <LogoutButton />
		{userGuidebookRole ? (
		  <>
            <label htmlFor="fileUrl" style={{color: '#fff'}}>Library</label>
            <select id="fileUrl" value={selectedFileUrl} onChange={(e) => setSelectedFileUrl(e.target.value)}>
            {fileUrlOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
            </select>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>
            <Viewer theme="dark" fileUrl={selectedFileUrl} plugins={[defaultLayoutPluginInstance]} defaultScale={SpecialZoomLevel.PageFit} viewMode={ViewMode.DualPage} />
		  </>
		) : (<div style={{color: '#fff'}}>Sorry, but you do not have access to the Guidebook.</div>)
		}
      </Stack>
	  </>
	) : (<Stack direction="column" spacing="2" justifyContent="center" alignItems="center"><LoginButton /></Stack>)
	}
	</div>
  );
};

export default GuideBook;