import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useSpring, animated } from 'react-spring';
import React, { useState, useEffect } from 'react';
import Posts from './Posts';

const Home = () => {
  const [showCardDeck, setShowCardDeck] = useState(false);
  const cardDeckAnimation = useSpring({
    from: { transform: 'translateX(-400%)' },
    to: { transform: showCardDeck ? 'translateX(0%)' : 'translateX(-400%)' },
  });
useEffect(() => {
  const timer = setTimeout(() => {
    setShowCardDeck(true);
  }, 1000);
  return () => clearTimeout(timer);
}, []);

  return (
	<div className="container">
		<div className="grid-container">
		  <div className="grid-item">
			<img src="introducing.png" />
		  </div>
		  <div className="grid-item">
		  </div>
		  <div className="grid-item">
			<animated.div style={cardDeckAnimation}>
				<img src="card_deck.png" />
			</animated.div>
		  </div>
		  <div className="grid-item">
			<img src="deck.png" />
		  </div>
		</div>
		<div className="full-width">
		  <img src="cards_flat.png" />
		</div>
		<div className="full-width">
			<Carousel showThumbs={false} autoPlay={true} interval={5000} infiniteLoop={true} showStatus={false} showIndicators={false}>
				<div key='0' style={{color:"#fff"}} className="carousel-item">
				  <h3>Experience the Magic</h3>
				  <p>Unlock the transformative power of the NUMO Oracle Deck and embark on a profound journey of self-discovery.<br />
				  Each card holds a sacred message, guiding you to embrace your authentic self, find clarity, and unleash your limitless potential.</p>
				</div>
				<div key='1' style={{color:"#fff"}} className="carousel-item">
				  <h3>Awaken Your Inner Wisdom</h3>
				  <p>Dive deep into the depths of your inner wisdom with the NUMO Oracle Deck.<br />
				  This powerful tool illuminates the path to self-discovery, guiding you towards profound insights and awakening.<br />
				  Each card holds a sacred message, inviting you to tap into your intuition and embrace your truest self.<br />
				  With its enchanting symbolism and empowering messages, the NUMO Oracle Deck serves as a catalyst for personal growth and transformation.<br />
				  Awaken your inner wisdom and embark on a transformative journey of self-discovery with this extraordinary deck.</p>
				</div>
				<div key='2' style={{color:"#fff"}} className="carousel-item">
				  <h3>Unlock the Mysteries of Life</h3>
				  <p>Unlock the mysteries of life with the NUMO Oracle Deck.<br />
				  This extraordinary deck holds the keys to hidden knowledge and profound revelations.<br />
				  Each card is a doorway to a deeper understanding of yourself and the world around you.<br />
				  Delve into the mystical symbols and intuitive messages that grace each card, and embark on a journey of self-discovery and enlightenment.<br />
				  With the NUMO Oracle Deck as your guide, you'll uncover the secrets that will illuminate your path and unlock the mysteries of life.</p>
				</div>
				<div key='3' style={{color:"#fff"}} className="carousel-item">
				  <h3>Tap into Ancient Wisdom</h3>
				  <p>The NUMO Oracle Deck is not just a set of cards; it is a spiritual companion that supports and empowers you on your journey.
				  It encourages you to trust your intuition and inner wisdom, guiding you to make conscious choices and embrace your authentic self.
				  The deck serves as a mirror, reflecting back to you the beauty, resilience, and unlimited possibilities that lie within.<br />
				  With the NUMO Oracle Deck, you will discover that self-transformation is not a destination but a continuous and sacred process.
				  It invites you to delve into the depths of your being, heal old wounds, and embrace your unique gifts.
				  The deck encourages you to embrace change, release limiting beliefs, and step into your true power.</p>
				</div>
			</Carousel>
		</div>
		<div className="grid-item">
	      <Posts />
		</div>
	</div>
  );
};

export default Home;
