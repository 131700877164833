import React from 'react';
import './Card.css';

const Card = ({ frontImage, flipped, backImage, onClick }) => {
  return (
    <div className={`cardgamecard ${flipped ? '' : 'flipped'}`} onClick={onClick}>
      <div className="cardgamecardfront"><img src={frontImage} alt="Card Front" className="overlay-image" /></div>
      <div className="cardgamecardback"><img src={backImage} alt="Card Back" className="overlay-image" /></div>
    </div>
  );
};

export default Card;