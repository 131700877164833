import React, { lazy, Suspense } from 'react';

const AdminPanel = lazy(() => import('./AdminPanel'));

function AdminPanelLazy() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AdminPanel />
    </Suspense>
  );
}

export default AdminPanelLazy;