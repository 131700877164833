import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  const emailData = {
    Messages: [
      {
        From: {
          Email: 'admin@numoracle.com',
          Name: `${formData.name}`
        },
        To: [
          {
            Email: 'numooracle@gmail.com',
            Name: 'Numoracle'
          }
        ],
        Subject: 'Contact Form Submission',
        TextPart: `Message from: ${formData.name} \nEmail: ${formData.email}\nPhone Number: ${formData.phoneNumber}\nMessage: ${formData.message}`,
        HTMLPart: `<h3>Contact Form Submission</h3><p><strong>From:</strong> ${formData.name}}</p><p><strong>Email:</strong> ${formData.email}</p><p><strong>Phone Number:</strong> ${formData.phoneNumber}</p><p><strong>Message:</strong> ${formData.message}</p>`,
        CustomID: 'ContactFormSubmission'
      }
    ]
  };

    try {
    const response = await fetch('https://www.numoracle.com/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailData)
    });

      if (response.ok) {
        setMessage('Email sent successfully');
        setErrorMessage('');
      } else {
        setErrorMessage('Failed to send email');
        setMessage('');
      }
    } catch (error) {
      setErrorMessage(error.toString());
      setMessage('');
    }
  };

  return (
    <div className="centered-tab-content">
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{
        mt: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
	  <Grid container direction="column" spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            id="name"
            name="name"
			label="Name"
            value={formData.firstName}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            id="email"
            name="email"
			label="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
			label="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
		    multiline
			fullWidth
            rows={8}
            id="message"
            name="message"
			label="Message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
		  <button variant="contained" type="submit">Submit</button>
        </Grid>
      {message && <div className="contact-success-message"><p>{message}</p></div>}
      {errorMessage && <div className="contact-error-message"><p>{errorMessage}</p></div>}
      </Grid>
      </Box>
    </div>
  );
};

export default ContactUs;
