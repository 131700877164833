import React, {useState, useEffect} from 'react';
import {useSprings, animated} from 'react-spring';
import Card from './Card';
import './CardGame.css';
const generateBackImageFilenames = (cardNames, elements) => {
  const backImages = [];
  cardNames.forEach((cardName) => {
    elements.forEach((element) => {
      const filename = `images/${cardName}-${element}.png`;
      backImages.push(filename);
    });
  });
  return backImages;
};
const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
	shuffledArray[i].flipped = (Math.random() < 0.5 ? false : true);
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};
const frontImage = 'card-front.jpg';
const cardNames = ['01cauldron', '25sword', '38cord', '47spear', '69stone'];
const elements = ['air', 'earth', 'fire', 'spirit', 'water'];
const backImageFilenames = generateBackImageFilenames(cardNames, elements);
const cards = backImageFilenames.map((filename, index) => ({id: index, backImage: filename, flipped: false}));
const deckPosition = {x: 0, y: -200};
const cardHolderPositions = [
  {x: -100, y: 0},
  {x: 0, y: 0},
  {x: 100, y: 0},
];

const fixFilename = (filename, flipped=false) => {
	let fn = filename.replace("images/","").replace(".png","");
	if(flipped){
		fn = fn.replace(/^(\d)(\d)([a-zA-Z]+)/, '$2$1$3');
	}
	return(fn.slice(0, 1) + fn.slice(2));
}

const CardGame = ({onCardClick}) => {
  const [selectedDropdownValues, setSelectedDropdownValues] = useState(["", "", ""]);
  const [flippedCards, setFlippedCards] = useState(Array(3).fill(false));
  const [deckClicked, setDeckClicked] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [animationPerformed, setAnimationPerformed] = useState(false);
  const [selectedCards, setSelectedCards] = useState(shuffleArray(cards).slice(0, 3));
  const handleDropdownChange = (e, index) => {
    const newSelectedValues = [...selectedDropdownValues];
    newSelectedValues[index] = e.target.value;
    setSelectedDropdownValues(newSelectedValues);
  };
  const handleCardClick = (index) => {
    setFlippedCards((prevFlippedCards) => {
      const updatedFlippedCards = [...prevFlippedCards];
      updatedFlippedCards[index] = !prevFlippedCards[index];
      return updatedFlippedCards;
    });
  };
  const sendDeck = () => {
    if (!deckClicked) {
      setDeckClicked(true);
    }
  };
  useEffect(() => {
    if (deckClicked) {
	  setAnimationPerformed(false);
      const allDropdownsSelected = selectedDropdownValues.every(value => value !== "");
      const shuffled = !allDropdownsSelected ? shuffleArray(cards).slice(0, 3) : selectedDropdownValues.map((backImage, index) => ({id: index, backImage: backImage.replace('_',''), flipped: backImage.startsWith('_')}));
      const newSelectedCards = shuffled.map((card, index) => {
      const position = cardHolderPositions[index];
      const toRot = Math.floor(Math.random() * 5) + (shuffled[index].flipped ? 178 : 358);
      return {
        ...card,
        position: 'relative',
        top: `${position.y - (index * 150)}px`,
        left: `${position.x - 20 + (index * 20)}px`,
        transform: `rotate(${toRot}deg)`,
      };
      });
      setSelectedCards(newSelectedCards);
      onCardClick(newSelectedCards);
	  setResetState(true);
	  setTimeout(() => {setResetState(false);}, 200);
    }
  }, [deckClicked]);
  const springs = useSprings(
    3,
    selectedCards.map((_, index) => {
      if (deckClicked) {
        return {
          to: async (next) => {
            await new Promise((resolve) => setTimeout(resolve, 200 * index));
            const position = cardHolderPositions[index];
            const toRot = (Math.floor(Math.random() * 5) + (selectedCards[index].flipped ? 178 : 358));
            await next({
              position: 'relative',
              top: `${position.y - (index * 150)}px`,
              left: `${position.x - 20 + (index * 20)}px`,
              transform: `rotate(${toRot}deg)`,
            });
            if (index === 2) {
              setAnimationPerformed(true);
              setDeckClicked(false);
            }
          },
          from: {
            position: 'absolute',
            top: `${deckPosition.y - (index * 150)}px`,
            left: `${deckPosition.x}px`,
            transform: `rotate(0deg)`,
          },
          config: {
            tension: 150,
            friction: 50
          },
		  reset: resetState,
        };
      } else if (animationPerformed) {
        return {
          to: {
            position: `${selectedCards[index].position}`,
            top: `${selectedCards[index].top}px`,
            left: `${selectedCards[index].left}px`,
            transform: `${selectedCards[index].transform}`,
          },
          from: {
            position: `${selectedCards[index].position}`,
            top: `${selectedCards[index].top}px`,
            left: `${selectedCards[index].left}px`,
            transform: `${selectedCards[index].transform}`,
          },
          config: {
            tension: 150,
            friction: 50
          },
        };
      } else {
        return {
          to: async (next) => {
            await next({
              position: 'absolute',
              top: `${deckPosition.y - (index * 150)}px`,
              left: `${deckPosition.x}px`,
              transform: `rotate(0deg)`,
            });
          },
          from: {
            position: 'absolute',
            top: `${deckPosition.y - (index * 150)}px`,
            left: `${deckPosition.x}px`,
            transform: `rotate(0deg)`,
          },
          config: {
            tension: 150,
            friction: 50
          },
        };
      }
    })
  );
  return (
    <div className = "card-game" >
	<div className="dropdown-container">
	  {selectedCards.map((card, index) => (
		<div key={index} className="dropdown-wrapper">
		  <select className="imgSelect" value={selectedDropdownValues[index]} onChange={(e) => handleDropdownChange(e, index)}>
			{backImageFilenames.map((filename, idx) => (
			  <><option key={idx} value={filename}>
				{fixFilename(filename)}
			  </option>
			  <option key={idx} value={'_' + filename}>
				{fixFilename(filename, true)}
			  </option></>
			))}
		  </select>
		</div>
	  ))}
	</div>
      <div className = "deck" onClick = {() => {sendDeck();}} style = {{backgroundImage: `url(${frontImage})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
      </div>
	  <div className = "card-holders" >
  	    {springs.map((props, index) => (
	      <animated.div key = {index} style = {props}>
            <Card frontImage = {frontImage} backImage = {selectedCards[index].backImage} flipped = {flippedCards[index]} onClick = {() => handleCardClick(index)} />
		  </animated.div>
          ))
        }
	  </div>
    </div>
  );
};
export default CardGame;