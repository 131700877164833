import React, { useState, useEffect } from 'react';
import ContactUs from './ContactUs';
import "https://js.stripe.com/v3/buy-button.js"
import { useAuth0 } from "@auth0/auth0-react";
import AdminPanelLazy from './AdminPanelLazy';

function Store() {
  const {user, isAuthenticated, isLoading, error} = useAuth0();
  const [showAdmin, setShowAdmin] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const handleContactUsClick = () => {
    setShowContactUs(true);
  };

  let[htmlFileString, setHtmlFileString] = useState();

  async function fetchHtml() {
    setHtmlFileString(await (await fetch(`Store.html`)).text());
  }
  useEffect(() => {
    fetchHtml();
  }, []);
  
  const toggleAdminPanel = () => {
    setShowAdmin(!showAdmin);
  };

  return (
    <div className="centered-tab-content" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {!isLoading && isAuthenticated && user["roles/roles"].includes("Admin") ? (
        <>
          <div style={{position: 'fixed', top: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><button onClick={toggleAdminPanel}>{!showAdmin ? 'Show' : 'Hide'} Admin</button></div>
          {showAdmin ? (
            <div style={{ color: '#fff', position: 'relative', top: '0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AdminPanelLazy />
            </div>
          ) : null}
        </>
      ) : null}
	  {!showAdmin ?
	    (<>
          {showContactUs ? (
            <ContactUs />
          ) : (
            <div style={{color: '#fff'}}>
		      <div className="grid-item">
  		        <div dangerouslySetInnerHTML={{ __html: htmlFileString }} />
		      </div>
              <footer style={{padding: '10px', textAlign: 'center'}}><a style={{color: '#fff'}} href="#" onClick={handleContactUsClick}>Contact Us</a></footer>
            </div>
          )}
	    </>):
		(<></>)
	  }
    </div>
  );
}

export default Store;