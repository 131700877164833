// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardgamecard {
  width: 100px;
  height: 150px;
  border: 1px solid #000;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform-origin: center;
}

.cardgamecard.flipped {
  transform: rotateY(180deg);
}

.cardgamecardfront,
.cardgamecardback {
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #000;
}

.cardgamecardback {
  transform: rotateY(180deg);
}

.overlay-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 1.0;
}`, "",{"version":3,"sources":["webpack://./src/components/Card.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,4BAA4B;EAC5B,0BAA0B;EAC1B,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;EAEE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,6BAA6B;EAC7B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,WAAW;EACX,YAAY;EACZ,YAAY;AACd","sourcesContent":[".cardgamecard {\n  width: 100px;\n  height: 150px;\n  border: 1px solid #000;\n  border-radius: 8px;\n  cursor: pointer;\n  position: relative;\n  transform-style: preserve-3d;\n  transition: transform 0.5s;\n  transform-origin: center;\n}\n\n.cardgamecard.flipped {\n  transform: rotateY(180deg);\n}\n\n.cardgamecardfront,\n.cardgamecardback {\n  background-color: #000;\n  color: #fff;\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  backface-visibility: hidden;\n  background-color: transparent;\n  border-radius: 8px;\n  border: 1px solid #000;\n}\n\n.cardgamecardback {\n  transform: rotateY(180deg);\n}\n\n.overlay-image {\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  width: 100%;\n  height: 100%;\n  opacity: 1.0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
