import React, { useState, useEffect, SyntheticEvent, ReactNode } from 'react';
import { useSpring, animated } from 'react-spring';
import Box from '@mui/material/Box';
import Home from './components/Home';
import Chat from './components/Chat';
import Store from './components/Store';
import GuideBook from './components/GuideBook';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other} >
      {value === index && (
        <Box sx={{ p: 4 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const App = () => {
  const [value, setValue] = React.useState(0);
  const [isCardFlipped, setIsCardFlipped] = useState([true, false, false, false]);

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
	sx: `flex: 1 1 55%; max-width: 55%; margin: 0 4px; box-sizing: border-box; display: flex; flex-direction: column; justify-content: space-between;`
  };
}

const handleCardMouseOver = (index) => {
  setIsCardFlipped((prevFlips) => {
    const newFlips = [...[false, false, false, false]];
    newFlips[index] = true;
    return newFlips;
  });
};

const handleCardMouseOut = (index) => {
  setIsCardFlipped((prevFlips) => {
    const newFlips = [...prevFlips];
    newFlips[value] = true;
    if (index !== value) {
      newFlips[index] = false;
    }
    return newFlips;
  });
};

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setIsCardFlipped((prevFlips) => {
      const newFlips = prevFlips.map((flip, i) => (i === newValue ? true : false));
      return newFlips;
    });
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleReset = async() => {
    setValue(0);
    await new Promise((resolve) => setTimeout(resolve, 100));
    setValue(1);
  };

  return (
    <Box sx={{ bgolor: '#000', background: 'linear-gradient(to bottom, #000000, #666666)', width: '100%', height: '100%'}}>
      <div style={{ backgroundColor: '#000', minHeight: '100vh', background: 'linear-gradient(to bottom, #666666, #000000)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
	  <AppBar position="static" style={{backgroundColor: '#000', background: 'linear-gradient(to bottom, #666666, #000000)'}}>
      <Tabs value={value} onChange={handleChange} variant="fullWidth" centered>
        <Tab {...a11yProps(0)} icon=<div className={`card-inner ${isCardFlipped[0] ? 'flipped' : ''}`}><div className="card-front"><img src="card1.jpg" /></div><div className="card-back"><span>Home</span></div></div> onMouseOver={() => handleCardMouseOver(0)} onMouseOut={() => handleCardMouseOut(0)} />
        <Tab {...a11yProps(1)} icon=<div className={`card-inner ${isCardFlipped[1] ? 'flipped' : ''}`}><div className="card-front"><img src="card2.jpg" /></div><div className="card-back"><span>Chat</span></div></div> onMouseOver={() => handleCardMouseOver(1)} onMouseOut={() => handleCardMouseOut(1)} />
        <Tab {...a11yProps(2)} icon=<div className={`card-inner ${isCardFlipped[2] ? 'flipped' : ''}`}><div className="card-front"><img src="card3.jpg" /></div><div className="card-back"><span>Buy Now</span></div></div> onMouseOver={() => handleCardMouseOver(2)} onMouseOut={() => handleCardMouseOut(2)} />
        <Tab {...a11yProps(3)} icon=<div className={`card-inner ${isCardFlipped[3] ? 'flipped' : ''}`}><div className="card-front"><img src="card4.jpg" /></div><div className="card-back"><span>Guidebook</span></div></div> onMouseOver={() => handleCardMouseOver(3)} onMouseOut={() => handleCardMouseOut(3)} />
      </Tabs>
	  </AppBar>
        <TabPanel value={value} index={0} dir='ltr'>
          <Home />
        </TabPanel>
        <TabPanel value={value} index={1} dir='ltr'>
          <Chat doReset={handleReset} />
        </TabPanel>
        <TabPanel value={value} index={2} dir='ltr'>
          <Store />
        </TabPanel>
        <TabPanel value={value} index={3} dir='ltr'>
          <GuideBook />
        </TabPanel>
	</div>
    </Box>
  );
};

export default App;