import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

function Posts() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
      const response = await fetch('/api/getposts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const data = await response.json();
      const posts = data.map((item) => ({
        id: item["id"],
        post: item["post"],
        image: item["image"],
        url: item["url"],
      }));
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setPosts(posts);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <div style={{color: '#fff'}}>
	  <div style={{textAlign: 'center'}}><h3>Blog</h3></div>
	  <Carousel showThumbs={false} autoPlay={true} interval={20000} infiniteLoop={true} showStatus={false} showIndicators={false}>
      {posts.map((post, index) => (
        <div key={index}>
          <p>{post.post}</p>
          <img src={post.image} />
          <a href={post.url}>{post.url}</a>
        </div>
      ))}
	  </Carousel>
    </div>
  );
}

export default Posts;
